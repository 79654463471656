import dotenv from 'dotenv';
dotenv.config();
const request = require('request');

async function exists(route, url) {
    try {
        let data = new Promise(function(resolve, reject){
            request(process.env.REACT_APP_API_URL+route+encodeURIComponent(url), function (error, response, body) {
                // in addition to parsing the value, deal with possible errors
                if (error || response.statusCode !== 200) return reject(error);
                try {
                    // JSON.parse() can throw an exception if not valid JSON
                    resolve(JSON.parse(body));
                } catch(e) {
                    reject(e);
                }
            });
        });
        return data;
    } catch(err) {
        console.log(err);
        return false;
    }
}

export default exists;
// let rgbToHex = function (rgb) {
//     var hex = Number(rgb).toString(16);
//     if (hex.length < 2) {
//         hex = '0' + hex;
//     }
//     return hex;
// };

// let fullColorHex = function (r, g, b) {
//     var red = rgbToHex(r);
//     var green = rgbToHex(g);
//     var blue = rgbToHex(b);
//     return '#' + red + green + blue;
// };

function find_color(grade) {
if (grade >= 70) {
    return "#95d85b";
    } else if (grade >= 40) {
    return "#fd921f";
    } else {
    return "#ff1818";
    }
}

export default find_color;
import React from 'react';
import Menu from './Components/Menu';
import Home from './Components/pages/Home'
import Stats from './Components/pages/Stats';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './css/main.css';

function App() {
  return (
    <CookiesProvider>
        <Router>
            <header>
                <Menu />
            </header>
                <Switch>
                    <Route path='/' exact component={Home}/>
                    <Route path='/Stats' exact component={Stats}/>
                </Switch>
        </Router>
    </CookiesProvider>
  );
}

export default App;

function getMessage(Language, page) {
    if (page === 0) {
        if (!Language) {
            return {
                placeholder: 'Check an URL',
                title: 'Check the ECO-conception of a web page',
                carbon: ['Carbon footprint (in gram per load): ', ' g per page load'],
                grade: ['Grade: ', '/100'],
                green: ['Hosted at a green host: ', 'Some documentation: ', "A green host is a domain which server's run on green energy", "The website's host does not seem to run on green energy !"],
                loading: 'Loading...',
                check: 'Check !',
                size: ['Page size in MB: ', 'Watt-hour per load: '],
                files: {
                    total: 'Total of loaded files: ',
                    images: 'Images: ',
                    css: 'CSS files: ',
                    scripts: 'Scripts: ',
                    font: 'Font files: ',
                    medias: 'Medias: ',
                    other: 'Other files: '
                },
                topbar: ['About us', 'Check an url', 'Stats by domain']
            };
        }
        if (Language.value === 'en') {
            return {
                placeholder: 'Check an URL',
                title: 'Check the ECO-conception of a web page',
                carbon: ['Carbon footprint (in gram per load): ', ' g per page load'],
                grade: ['Grade: ', '/100'],
                green: ['Hosted at a green host: ', 'Some documentation: ', "A green host is a domain which server's run on green energy", "The website's host does not seem to run on green energy !"],
                loading: 'Loading...',
                check: 'Check !',
                size: ['Page size in MB: ', 'Watt-hour per load: '],
                files: {
                    total: 'Total of loaded files: ',
                    images: 'Images: ',
                    css: 'CSS files: ',
                    scripts: 'Scripts: ',
                    font: 'Font files: ',
                    medias: 'Medias: ',
                    other: 'Other files: '
                },
                topbar: ['About us', 'Check an url', 'Stats by domain']
            };
        } else if (Language.value === 'fr') {
            return {
                placeholder: 'Testez une URL',
                title: 'Testez l\'ÉCO-conception d\'une URL',
                carbon: ['Empreinte carbone (en grammes par chargement): ', ' g par chargement'],
                grade: ['Note: ', '/100'],
                green: ['Hébergé chez un host vert: ', 'Un peu de documentation: ', "Un hébergeur vert fait fonctionner ses serveurs à l'énergie renouvelable", "L'hébergeur de ce site ne semble pas utiliser d'énergie verte !"],
                loading: 'Chargement...',
                check: 'Tester !',
                size: ['Taille de la page en MO: ', 'Watt-heure par chargement: '],
                files: {
                    total: 'Total de fichiers chargés: ',
                    images: 'Images: ',
                    css: 'Fichiers CSS: ',
                    scripts: 'Scripts: ',
                    font: 'Fichiers de police: ',
                    medias: 'Medias: ',
                    other: 'Autres fichiers: '
                },
                topbar: ['Qui sommes nous', 'Scanner une url', 'Stats par domaine']
            };
        } else {
            return {
                placeholder: 'Check an URL',
                title: 'Check the ECO-conception of a web page',
                carbon: ['Carbon footprint (in gram per load): ', ' g par page load'],
                grade: ['Grade: ', '/100'],
                green: ['Hosted at a green host: ', 'Some documentation: ', "A green host is a domain which server's run on green energy", "The website's host does not seem to run on green energy !"],
                loading: 'Loading...',
                check: 'Check !',
                size: ['Page size in MB: ', 'Watt-hour per load: '],
                files: {
                    total: 'Total of loaded files: ',
                    images: 'Images: ',
                    css: 'CSS files: ',
                    scripts: 'Scripts: ',
                    font: 'Font files: ',
                    medias: 'Medias: ',
                    other: 'Other files: '
                },
                topbar: ['About us', 'Check an url', 'Stats by domain']
            };
        }
    }
    if (page === 1) {
        if (!Language) {
            return {
                placeholder: 'Check a domain',
                title: 'Check the ECO-conception of a domain',
                domain: 'Domain:',
                averagegrade: ['Average grade: ', '/100'],
                green: ['Hosted at a green host: ', 'Some documentation: ', "A green host is a domain which server's run on green energy", "The website's host does not seem to run on green energy !"],
                loading: 'Loading...',
                check: 'Check !',
                averagestats: ['Average size and carbon footprint:', 'Size in MB: ', 'Watt-hour per load: ', 'Carbon footprint (in gram per load): '],
                totalstats: ['Total size and carbon footprint:', 'Size in MB: ', 'Watt-hour per load: ', 'Carbon footprint (in gram per load): '],
                files: {
                    average: 'Average number of files loaded on each pages: ',
                    total: 'Total files loaded on every pages: ',
                    images: 'Images: ',
                    css: 'CSS files: ',
                    scripts: 'Scripts: ',
                    font: 'Font files: ',
                    medias: 'Medias: ',
                    other: 'Other files: '
                },
                topbar: ['About us', 'Check an url', 'Stats by domain']
            };
        }
        if (Language.value === 'en') {
            return {
                placeholder: 'Check a domain',
                title: 'Check the ECO-conception of a domain',
                domain: 'Domain:',
                averagegrade: ['Average grade: ', '/100'],
                green: ['Hosted at a green host: ', 'Some documentation: ', "A green host is a domain which server's run on green energy", "The website's host does not seem to run on green energy !"],
                loading: 'Loading...',
                check: 'Check !',
                averagestats: ['Average size and carbon footprint:', 'Size in MB: ', 'Watt-hour per load: ', 'Carbon footprint (in gram per load): '],
                totalstats: ['Total size and carbon footprint:', 'Size in MB: ', 'Watt-hour per load: ', 'Carbon footprint (in gram per load): '],
                files: {
                    average: 'Average number of files loaded on each pages: ',
                    total: 'Total files loaded on every pages: ',
                    images: 'Images: ',
                    css: 'CSS files: ',
                    scripts: 'Scripts: ',
                    font: 'Font files: ',
                    medias: 'Medias: ',
                    other: 'Other files: '
                },
                topbar: ['About us', 'Check an url', 'Stats by domain']
            };
        } else if (Language.value === 'fr') {
            return {
                placeholder: 'Testez un domaine',
                title: 'Testez l\'ÉCO-conception d\'un domaine',
                domain: 'Domaine:',
                averagegrade: ['Note moyenne: ', '/100'],
                green: ['Hébergé chez un host vert: ', 'Un peu de documentation: ', "Un hébergeur vert est fait fonctionner ses serveurs à l'énergie renouvelable", "L'hébergeur de ce site ne semble pas utiliser d'énergie verte !"],
                loading: 'Chargement...',
                check: 'Tester !',
                averagestats: ['Taille et empreinte carbone moyennes des pages de ce domaine:', 'Taille en MO: ', 'Watt-heure moyens par chargement: ', 'Empreinte carbone (en gramme par chargement): '],
                totalstats: ['Taille totale et émissions totales de carbone des pages connues de ce domaine: ', 'Taille en MO: ', 'Watt-heure moyens par chargement: ', 'Empreinte carbone (en gramme par chargement): '],
                files: {
                    average: 'Moyenne de fichiers chargés sur les pages de ce domaine: ',
                    total: 'Total de fichiers chargés sur les pages de ce domaine: ',
                    images: 'Images: ',
                    css: 'Fichiers CSS: ',
                    scripts: 'Scripts: ',
                    font: 'Fichiers de police: ',
                    medias: 'Medias: ',
                    other: 'Autres fichiers: '
                },
                topbar: ['Qui sommes nous', 'Scanner une url', 'Stats par domaine']

            };
        } else {
            return {
                placeholder: 'Check a domain',
                title: 'Check the ECO-conception of a domain',
                domain: 'Domain:',
                averagegrade: ['Average grade: ', '/100'],
                green: ['Hosted at a green host: ', 'Some documentation: ', "A green host is a domain which server's run on green energy", "The website's host does not seem to run on green energy !"],
                loading: 'Loading...',
                check: 'Check !',
                averagestats: ['Average size and carbon footprint:', 'Size in MB: ', 'Watt-hour per load: ', 'Carbon footprint (in gram per load): '],
                totalstats: ['Total size and carbon footprint:', 'Size in MB: ', 'Watt-hour per load: ', 'Carbon footprint (in gram per load): '],
                files: {
                    average: 'Average number of files loaded on each pages: ',
                    total: 'Total files loaded on every pages: ',
                    images: 'Images: ',
                    css: 'CSS files: ',
                    scripts: 'Scripts: ',
                    font: 'Font files: ',
                    medias: 'Medias: ',
                    other: 'Other files: '
                },
                topbar: ['About us', 'Check an url', 'Stats by domain']
            };
        }
    }
    if (page === 2) {
        if (!Language) {
            return {
                wrongUrl: 'This URL is not valid !',
                wrongDomain: 'This domain is not valid !',
                error: 'An error happened on our side, sorry !',
                noData: ['This domain has no stats yet ! Statistics are gathered by users use of the extension and the website !', 'Come back soon, we\'ll eventually collect some stats for this domain !'],
            };
        }
        if (Language.value === 'en') {
            return {
                wrongUrl: 'This URL is not valid !',
                wrongDomain: 'This domain is not valid !',
                error: 'An error happened on our side, sorry !',
                noData: ['This domain has no stats yet ! Statistics are gathered by users use of the extension and the website !', 'Come back soon, we\'ll eventually collect some stats for this domain !'],
            };
        } else if (Language.value === 'fr') {
            return {
                wrongUrl: 'Cette URL n\'est pas valide !',
                wrongDomain: 'Ce domaine n\'est pas valide !',
                error: 'Une erreur est arrivée de notre côté, désolé !',
                noData: ['Ce domaine n\'a pas encore de statistiques ! Elles sont récupérées par les utilisateurs du site et de notre extension !', 'Retestez bientôt, nous devrious avoir des données très bientôt'],
            };
        } else {
            return {
                wrongUrl: 'This URL is not valid !',
                wrongDomain: 'This domain is not valid !',
                error: 'An error happened on our side, sorry !',
                noData: ['This domain has no stats yet ! Statistics are gathered by users use of the extension and the website !', 'Come back soon, we\'ll eventually collect some stats for this domain !'],
            };
        }
    }
}

export default getMessage;
import React, { useState } from "react";
import Select from 'react-select';
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Logo from "../img/logo-texte.svg";
import dotenv from 'dotenv';
import bg_img_right from '../img/tgv-site-right.png';
import bg_img_top from '../img/tgv-site-top.png';
import bg_img_left from '../img/tgv-site-left.png';
import bg_img_bottom from '../img/tgv-site-bottom.png';
import '../css/main.css'
import getMessage from "./components/Get-message";

dotenv.config();

function Menu() {
    const [click, setClick] = useState(true);
    const [HomeActive, setHomeActive] = useState(true);
    const [StatsActive, setStatsActive] = useState(false);
    const [cookies, setCookie] = useCookies(['Language']);

    const selectStyles = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: (isSelected || (!cookies.Language && data.value === 'en')) ? ('#95D85B') : (isFocused ? '#AFAFAF' : '#fff'),
              cursor: isFocused ? 'pointer' : 'default',
              maxHeight: 30,
              input: {
                maxLenght: 2,
                NoOptionsMessage: 'lol'
              }
            };
          },
    }

    const options = [
        { value: 'en', label: 'En' },
        { value: 'fr', label: 'Fr'}
    ];

    function handleSelect(selectedLanguage) {
        setCookie('Language', selectedLanguage, { path: '/' });
        console.log(selectedLanguage)
    }

    const handleClick = () => setClick(!click);

    const closeMenu = (route) => {
        setHomeActive(route === 'home' ? true : false)
        setStatsActive(route === 'stats' ? true : false)
        setClick(true)
    }

    const message = getMessage(cookies.Language, 0);
    return (
        <>
            <div className="nav">
                <div className={"menu-nav_item"}>
                    <a href="https://www.vikings.green"
                        target=""
                        rel="noopener noreferrer">
                            <img className="logo" src={Logo} />
                    </a>
                </div>
                <ul className={click ? "menu-nav" : "menu-nav open"}>
                    
                    <li className={click ? "menu-nav_item" + (HomeActive ? " active" : '') : "menu-nav_item open" + (HomeActive ? " active" : '')}>
                        <Link to="/" className="menu-nav_link" onClick={() => closeMenu('home')}>
                            {message.topbar[1]}
                        </Link>
                    </li>
                    <li className={click ? "menu-nav_item" + (StatsActive ? " active" : '') : "menu-nav_item open" + (StatsActive ? " active" : '')}>
                        <Link to="/Stats" className="menu-nav_link" onClick={() => closeMenu('stats')}>
                            {message.topbar[2]}
                        </Link>
                    </li>
                    <li>
                        <a className="menu-nav_link"
                            href="https://www.les-vikings.fr"
                            target="_blank"
                            rel="noopener noreferrer">
                            {message.topbar[0]}
                        </a>
                    </li>
                    <li className= "menu-nav_select">
                        <Select
                        value={cookies.Language}
                        onChange={handleSelect}
                        styles={selectStyles}
                        options={options}
                        placeholder={'EN'}
                        
                        />
                    </li>
                </ul>
            </div>
            
        </>
    )
}

export default Menu;
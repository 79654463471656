import * as React from "react";
import { useState, createContext, useContext } from "react";
import dotenv from 'dotenv';
import find_color from "../components/Find-color";
import Pie from "../components/Pie";
import Loading from "../components/Loading";
import getMessage from "../components/Get-message";
import print_green from "../components/Print-green";
import getGreen from "../components/Get-green";
import exists from "../components/Exists";
import toPercent from "../components/To-percent";
import logger from "../components/Logger";
import '../../css/main.css'
import IconButton from '@mui/material/IconButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import bg_img_right from '../../img/tgv-site-right.png';
import bg_img_top from '../../img/tgv-site-top.png';
import bg_img_left from '../../img/tgv-site-left.png';
import bg_img_bottom from '../../img/tgv-site-bottom.png';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useCookies } from "react-cookie";
import Footer from '../components/Footer';
import { Language } from "@mui/icons-material";

dotenv.config();
const request = require('request');

logger(process.env.REACT_APP_API_URL);

const details_url = process.env.REACT_APP_API_URL + 'detail-url/';

const AccordionContext = createContext(true);

function cutstr(str, length) {
    if (str.length <= length) {
        return str;
    }
    return str.substr(0, length) + '\u2026';
}


//bar top navigation avec: menu déroulant avec les langues, qui sommes nous
//sous le logo et the green viking: let's make the internet great again
async function request_page_details(url) {
    let data = new Promise(function(resolve, reject){
        request(details_url+encodeURIComponent(url), function (error, response, body) {
            // in addition to parsing the value, deal with possible errors
            if (error || response.statusCode !== 200) return reject(error);
            try {
                // JSON.parse() can throw an exception if not valid JSON
                resolve(JSON.parse(body));
            } catch(e) {
                reject(e);
            }
        });
    });
    return data;
}




function ContextAwareToggle({ eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );
    //{(!isCurrentEventKey) && (<ArrowDropDownIcon />)} {(isCurrentEventKey) && (<ArrowDropUpIcon />)}
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <IconButton
            style={{"background-color": "#fff", "margin-top": "auto"}}
            onClick={decoratedOnClick}
        >
            {(!isCurrentEventKey) && (<ArrowDropDownIcon />)} {(isCurrentEventKey) && (<ArrowDropUpIcon />)}
        </IconButton>
    );
}

function Home() {
    const [url, setUrl] = useState('');
    const [data, setData] = useState({basic: 'data'});
    const [cookies] = useCookies(['Language']);

    const Language = cookies.Language;
    const HandleSubmit = async (event) => {
        try {
            event.preventDefault();
            setData({basic: 'loading'});
            logger('checking if url exists')
            const verif = await exists('url-exists/', url);
            logger(verif)
            if (verif.exists === true) {
                setUrl(verif.url)
                fetchData(verif.url);
            } else {
                logger('wrong url');
                setData({basic: 'Wrong url', url: verif.url})
            }
        }
        catch(err) {
            console.log(err)
            setData({basic: 'ERROR', url: url});
        }
    }

    const fetchData = async (finalurl) => {
        const request = await request_page_details(finalurl);
        logger(request);
        let data = {};
        if (request.error === false) {
            logger(toPercent(request.imgCount, 0, 100));
            data = { basic: [request.url,
            (request.size * 0.000001).toFixed(5),
            Number(parseFloat(((1.805 / 1073741824) * request.size) * 1000).toFixed(6)),
            request.imgCount,
            request.cssCount,
            request.scriptCount,
            request.mediaCount,
            request.docCount,
            request.carbon,
            Number(parseFloat(request.grade).toFixed(0)),
            request.fontCount,
            request.screenshot], green: request};
        } else {
            data = {basic: 'error', url: url};
        }
        setData(data);
        logger(data);
    }

    //total des documents chargés expendable
    //abandonner le background grix, faire plein de colones
    //
    const message = getMessage(Language, 0);
    const error = getMessage(Language, 2);
    return (
        <main>
            <div className="img-bg">
                    <img className="img-bg_right" src={bg_img_right}/>
                    <img className="img-bg_top" src={bg_img_top}/>
                    <img className="img-bg_left" src={bg_img_left}/>
                    <img className="img-bg_bottom" src={bg_img_bottom}/>
            </div>
            <section className="home">
                <div className="title">
                    <h1>{message.title}</h1>
                </div>
                <form className="create" onSubmit={HandleSubmit}>
                        <input
                            type="text"
                            required value={url}
                            spellCheck={false}
                            placeholder={message.placeholder}
                            onChange={(event) => (setUrl(event.target.value))}
                        />
                    <button>{message.check}</button>
                </form>
                
                { (data.basic === 'Wrong url') && (
                    <section className="url-stats">
                    <div className="jobs">
                        <div className="jobs_job">
                        <h2 className="text-secondary">
                            {error.wrongUrl}
                            </h2>
                        </div>
                    </div>
                    </section>
                )}
                { (data.basic === 'error') && (
                    <section className="domain-stats">
                    <div className="jobs">
                        <div className="jobs_job">
                        <h2 className="text-secondary">
                            {error.error}
                            </h2>
                        </div>
                    </div>
                    </section>
                )}
                { (data.basic === 'loading') && (
                    <section className="url-stats">
                    <div className="jobs">
                        <div className="jobs_loading">
                            <Loading Type="Oval" />
                        </div>
                    </div>
                    </section>
                )}
                { (data.basic !== 'data' && data.basic !== 'loading' && data.basic !== 'Wrong url' && data.basic !== 'error') && (
                    <>
                        <section className="url-stats">
                            <Accordion className="results">
                                <Card className="results_tab">
                                    <div>
                                        <h2 className="text-secondary">
                                            {message.grade[0]}
                                        </h2>
                                        <Pie percentage={data.basic[9]} colour={find_color(data.basic[9])} size={3}/>
                                        <h2 className="text-secondary">
                                        URL:
                                        </h2>
                                            <a
                                            href={data.basic[0]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >
                                            {cutstr(data.basic[0], 40)}
                                            </a>

                                    </div>
                                    
                                </Card>
                                <Card className="results_tab">
                                    <div>
                                        <h2 className="text-tertiary">
                                        {message.size[0] + data.basic[1]}
                                        </h2>
                                        <h2 className="text-tertiary">
                                        {message.size[1] + data.basic[2]}
                                        </h2>
                                        <h2 className="text-tertiary">
                                        {message.carbon[0] + data.basic[8]}
                                        </h2>
                                    </div>
                                    <Card.Header style={{"background-color": "#fff", "border-bottom": "none", "margin-top": "auto"}}>
                                        <ContextAwareToggle eventKey="0"/>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <p>
                                            
                                            </p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="results_tab">
                                    <div>
                                        <h2 className="text-tertiary">
                                        {message.files.total}
                                        </h2>
                                        <Pie
                                            text={data.basic[3] + data.basic[4] + data.basic[5] + data.basic[6] + data.basic[7]}
                                            percentage={toPercent(data.basic[3] + data.basic[4] + data.basic[5] + data.basic[6] + data.basic[7], 75, 0)}
                                            colour={find_color(toPercent(data.basic[3] + data.basic[4] + data.basic[5] + data.basic[6] + data.basic[7], 75, 0))}
                                            size={2}
                                        />
                                    </div>
                                    <Card.Header style={{"backgroundColor": "#fff", "borderBottom": "none", "marginTop": "auto"}}>
                                        <ContextAwareToggle eventKey="1"/>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className='results_tab_detail visible docs'>
                                            <p className="text-dropped">
                                            {message.files.images}</p> <Pie
                                                text={data.basic[3]}
                                                percentage={toPercent(data.basic[3], 50, 0)}
                                                colour={find_color(Number(toPercent(data.basic[3], 50, 0)))}
                                                size={1}
                                            />
                                            <p className="text-dropped">
                                            {message.files.css}</p> <Pie
                                                text={data.basic[4]}
                                                percentage={toPercent(data.basic[4], 10, 0)}
                                                colour={find_color(toPercent(data.basic[4], 10, 0))}
                                                size={1}
                                            />
                                            <p className="text-dropped">
                                            {message.files.scripts}</p> <Pie
                                                text={data.basic[5]}
                                                percentage={toPercent(data.basic[5], 7, 0)}
                                                colour={find_color(toPercent(data.basic[5], 7, 0))}
                                                size={1}
                                            />
                                            <p className="text-dropped">
                                            {message.files.font}</p> <Pie
                                                text={data.basic[10]}
                                                percentage={toPercent(data.basic[10], 10, 0)}
                                                colour={find_color(toPercent(data.basic[10], 10, 0))}
                                                size={1}
                                            />
                                            <p className="text-dropped">
                                            {message.files.medias}</p> <Pie
                                                text={data.basic[7]}
                                                percentage={toPercent(data.basic[7], 5, 0)}
                                                colour={find_color(toPercent(data.basic[7], 5, 0))}
                                                size={1}
                                            />
                                            <p className="text-dropped">
                                            {message.files.other}</p> <Pie
                                                text={data.basic[6]}
                                                percentage={toPercent(data.basic[6], 3, 0)}
                                                colour={find_color(toPercent(data.basic[6], 3, 0))}
                                                size={1}
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="results_tab">
                                        <Card.Body className='results_tab_detail visible docs'>
                                            <img
                                            src={data.basic[11]}
                                            alt="Screenshot!"/>
                                        </Card.Body>
                                </Card>
                                <Card className="results_tab">
                                    <div>
                                    {print_green(getGreen(data.green, Language), 0, Language)}
                                    </div>
                                    <Card.Header style={{"backgroundColor": "#fff", "borderBottom": "none", "marginTop": "auto"}}>
                                        <ContextAwareToggle eventKey="3"/>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body className='results_tab_detail visible'>
                                            {print_green(getGreen(data.green, Language), 1, Language)}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </section>
                        <Footer/>
                    </>
                    ) }
            </section>
        </main>
    );
}

export default Home
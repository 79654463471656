import React from "react";
import { useState, createContext, useContext } from "react";
import dotenv from 'dotenv';
import '../../css/main.css'
import find_color from "../components/Find-color";
import Pie from "../components/Pie";
import Loading from "../components/Loading";
import print_green from "../components/Print-green";
import getGreen from "../components/Get-green";
import exists from "../components/Exists";
import toPercent from "../components/To-percent";
import logger from "../components/Logger";
import getMessage from "../components/Get-message";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import bg_img_right from '../../img/tgv-site-right.png';
import bg_img_top from '../../img/tgv-site-top.png';
import bg_img_left from '../../img/tgv-site-left.png';
import bg_img_bottom from '../../img/tgv-site-bottom.png';
import Footer from '../components/Footer';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { useCookies } from "react-cookie";
import { IconButton } from "@mui/material";
const request = require('request');

dotenv.config();
const domainstats_url = process.env.REACT_APP_API_URL + 'domain-stats/';

const AccordionContext = createContext(true);

async function request_page_details(url) {
    let data = new Promise(function(resolve, reject){
        request(domainstats_url+encodeURIComponent(url), function (error, response, body) {
            // in addition to parsing the value, deal with possible errors
            if (error || response.statusCode !== 200) return reject(error);
            try {
                // JSON.parse() can throw an exception if not valid JSON
                resolve(JSON.parse(body));
            } catch(e) {
                reject(e);
            }
        });
    });
    return data;
}

function ContextAwareToggle({ eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );
    //{(!isCurrentEventKey) && (<ArrowDropDownIcon />)} {(isCurrentEventKey) && (<ArrowDropUpIcon />)}
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <IconButton
            style={{"background-color": "#fff", "margin-top": "auto"}}
            onClick={decoratedOnClick}
        >
            {(!isCurrentEventKey) && (<ArrowDropDownIcon />)} {(isCurrentEventKey) && (<ArrowDropUpIcon />)}
        </IconButton>
    );
}

function Stats() {
    const [url, setUrl] = useState('');
    const [cookies] = useCookies(['Language']);
    const [data, setData] = useState({basic: 'data'});

    const Language = cookies.Language;
    const HandleSubmit = async (event) => {
        try {
            event.preventDefault();
            setData({basic: 'loading'});
            logger('checking if url exists')
            const verif = await exists('url-exists/', url);
            logger(verif)
            if (verif.exists === true) {
                fetchData(verif.url);
            } else {
                logger('wrong url');
                setData({basic: 'Wrong url', url: verif.url})
            }
        }
        catch(err) {
            console.log(err)
            setData({basic: 'ERROR', url: url});
        }
    }
    
    const fetchData = async (finalurl) => {
        const request = await request_page_details(finalurl);
        logger(request);
        let data;
        if (request.averageSize === 0) {
            data = { basic: 'This domain has no stats yet ! Statistics are gathered by users use of the extension and the website !'}
        } else if (!request.averageCarbon) {
            data = 'loading';
        } else {
            setUrl(request.url)
            logger(request.green)
            data = { basic : [
                    request.url,
                    (request.averageSize * 0.000001).toFixed(4),
                    request.averageImgCount,
                    request.averageFontCount,
                    request.averageCssCount,
                    request.averageScriptCount,
                    request.averageDocCount,
                    request.averageMediaCount,
                    request.averageCarbon,
                    Number(parseFloat(request.averageGrade).toFixed(2)),
                    (request.totalSize * 0.000001).toFixed(5),
                    request.totalImgCount,
                    request.totalFontCount,
                    request.totalCssCount,
                    request.totalScriptCount,
                    request.totalDocCount,
                    request.totalMediaCount,
                    request.totalCarbon,
                    request.greenweb
                ],
                green: request
            };
        }
        setData(data);
        logger(data);
    }
    const id = "data";
    const message = getMessage(Language, 1);
    const error = getMessage(Language, 2);
    return (
        <main>
            <div className="img-bg">
                    <img className="img-bg_right" src={bg_img_right}/>
                    <img className="img-bg_top" src={bg_img_top}/>
                    <img className="img-bg_left" src={bg_img_left}/>
                    <img className="img-bg_bottom" src={bg_img_bottom}/>
            </div>
            <>
            <section className="home" id={(data.basic !== 'data') ? id : data} >
    
                <div className="title">
                    <h1>{message.title}</h1>
                </div>
                <form className="create" onSubmit={HandleSubmit}>
                        <input
                        type="text"
                        required value={url}
                        spellCheck={false}
                        onChange={(event) => (setUrl(event.target.value))}
                        placeholder={message.placeholder}
                        />
                    
                    <button>{message.check}</button>

                </form>
                { (data.basic === 'Wrong url' || data.basic === 'error') && (
                    <section className="domain-stats">
                    <div className="jobs">
                        <div className="jobs_job">
                        <h2 className="text-secondary">
                            {error.wrongDomain}
                            </h2>
                        </div>
                    </div>
                    </section>
                )}
                { (data.basic === 'ERROR') && (
                    <section className="domain-stats">
                    <div className="jobs">
                        <div className="jobs_job">
                        <h2 className="text-secondary">
                            {error.error}
                            </h2>
                        </div>
                    </div>
                    </section>
                )}
                { (data.basic === 'loading') && (
                    <section className="url-stats">
                    <div className="jobs">
                        <div className="jobs_loading">
                            <Loading Type="Oval" />
                        </div>
                    </div>
                    </section>
                )}
                { (data.basic && data.basic !== 'data' && data.basic !== 'loading' && data.basic !== 'Wrong url' && data.basic !== 'error' && data.basic !== 'This domain has no stats yet ! Statistics are gathered by users use of the extension and the website !') && (
                        <>
                        <section className="domain-stats">
                            <Accordion className="results">
                                <Card className="results_tab">
                                    <div>
                                        <h2 className="text-secondary">
                                            {message.averagegrade[0]}
                                        </h2>
                                        <Pie percentage={data.basic[9]} colour={find_color(data.basic[9], 100)} size={3}/>
                                        <h2 className="text-secondary">
                                        {message.domain}
                                        </h2>
                                            <a
                                            href={data.basic[0]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            >
                                            {data.basic[0]}
                                            </a>

                                    </div>
                                    
                                </Card>
                                <Card className="results_tab">
                                    <div>
                                        <h2 className="text-secondary">
                                        {message.averagestats[0]}
                                        </h2>
                                        <h2 className="text-secondary">
                                        {message.averagestats[1] + data.basic[1]}
                                        </h2>
                                        <h2 className="text-secondary">
                                        {message.averagestats[3] + data.basic[8]}
                                        </h2>
                                    </div>
                                    <Card.Header style={{"background-color": "#fff", "border-bottom": "none", "margin-top": "auto"}}>
                                        <ContextAwareToggle eventKey="0"/>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <p>
                                            
                                            </p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="results_tab">
                                    <div>
                                        <h2 className="text-secondary">
                                        {message.files.average}
                                        </h2>
                                        <Pie
                                            text={data.basic[2] + data.basic[3] + data.basic[4] + data.basic[5] + data.basic[6] + data.basic[7]}
                                            percentage={toPercent(data.basic[2] + data.basic[3] + data.basic[4] + data.basic[5] + data.basic[6] + data.basic[7], 75, 1)}
                                            colour={find_color(toPercent(data.basic[2] + data.basic[3] + data.basic[4] + data.basic[5] + data.basic[6] + data.basic[7], 75, 1))}
                                            size={2}
                                        />
                                    </div>
                                    <Card.Header style={{"background-color": "#fff", "border-bottom": "none", "margin-top": "auto"}}>
                                        <ContextAwareToggle eventKey="1"/>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className='results_tab_detail visible docs'>
                                        <p className="text-dropped">
                                            {message.files.images}</p> <Pie
                                                text={data.basic[2]}
                                                percentage={toPercent(data.basic[2], 50, 0)}
                                                colour={find_color(toPercent(data.basic[2], 50, 0))}
                                                size={1}
                                                />
                                            
                                            <p className="text-dropped">
                                            {message.files.css}</p> <Pie
                                                text={data.basic[4]}
                                                percentage={toPercent(data.basic[4], 10, 1)}
                                                colour={find_color(toPercent(data.basic[4], 10, 1))}
                                                size={1}
                                                />
                                            
                                            <p className="text-dropped">
                                            {message.files.scripts}</p> <Pie
                                                text={data.basic[5]}
                                                percentage={toPercent(data.basic[5], 7, 0)}
                                                colour={find_color(toPercent(data.basic[5], 7, 0))}
                                                size={1}
                                                />
                                            <p className="text-dropped">
                                            {message.files.font}</p> <Pie
                                                text={data.basic[3]}
                                                percentage={toPercent(data.basic[3], 10, 0)}
                                                colour={find_color(toPercent(data.basic[3], 10, 0))}
                                                size={1}
                                                />
                                            <p className="text-dropped">
                                            {message.files.medias}</p> <Pie
                                                text={data.basic[7]}
                                                percentage={toPercent(data.basic[7], 5, 0)}
                                                colour={find_color(toPercent(data.basic[7], 5, 0))}
                                                size={1}
                                                />
                                            <p className="text-dropped">
                                            {message.files.other}</p> <Pie
                                                text={data.basic[6]}
                                                percentage={toPercent(data.basic[6], 3, 0)}
                                                colour={find_color(toPercent(data.basic[6], 3, 0))}
                                                size={1}
                                                />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="results_tab">
                                <div>
                                        <h2 className="text-secondary">
                                        {message.totalstats[0]}
                                        </h2>
                                        <h2 className="text-secondary">
                                        {message.totalstats[1] + data.basic[10]}
                                        </h2>
                                        <h2 className="text-secondary">
                                        {message.totalstats[3] + data.basic[17]}
                                        </h2>
                                    </div>
                                    <Card.Header style={{"background-color": "#fff", "border-bottom": "none", "margin-top": "auto"}}>
                                        <ContextAwareToggle eventKey="2"/>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body>
                                            <p>
                                            The bigger the page, the bigger the energy consuption
                                            </p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="results_tab">
                                    <div>
                                        <h2 className="text-secondary">
                                        {message.files.total}
                                        </h2>
                                        <Pie
                                            text={data.basic[11] + data.basic[12] + data.basic[13] + data.basic[14] + data.basic[15] + data.basic[16]}
                                            percentage={toPercent(data.basic[11] + data.basic[12] + data.basic[13] + data.basic[14] + data.basic[15] + data.basic[16], 75, 0)}
                                            colour={find_color(toPercent(data.basic[11] + data.basic[12] + data.basic[13] + data.basic[14] + data.basic[15] + data.basic[15], 75, 0))}
                                            size={2}
                                        />
                                    </div>
                                    <Card.Header style={{"background-color": "#fff", "border-bottom": "none", "margin-top": "auto"}}>
                                        <ContextAwareToggle eventKey="3"/>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body className='results_tab_detail visible docs'>
                                        <p className="text-dropped">
                                            {message.files.images}</p> <Pie
                                                text={data.basic[11]}
                                                percentage={toPercent(data.basic[2], 50, 0)}
                                                colour={find_color(toPercent(data.basic[2], 50, 0))}
                                                size={1}
                                        />
                                        
                                        <p className="text-dropped">
                                            {message.files.css}</p> <Pie
                                                text={data.basic[13]}
                                                percentage={toPercent(data.basic[4], 10, 0)}
                                                colour={find_color(toPercent(data.basic[4], 10, 0))}
                                                size={1}
                                        />
                                        <p className="text-dropped">
                                            {message.files.scripts}</p> <Pie
                                                text={data.basic[14]}
                                                percentage={toPercent(data.basic[5], 7, 0)}
                                                colour={find_color(toPercent(data.basic[5], 7, 0))}
                                                size={1}
                                        />
                                        <p className="text-dropped">
                                            {message.files.font}</p> <Pie
                                                text={data.basic[12]}
                                                percentage={toPercent(data.basic[3], 10, 0)}
                                                colour={find_color(toPercent(data.basic[3], 10, 0))}
                                                size={1}
                                        />
                                        <p className="text-dropped">
                                            {message.files.medias}:</p> <Pie
                                                text={data.basic[16]}
                                                percentage={toPercent(data.basic[7], 5, 0)}
                                                colour={find_color(toPercent(data.basic[7], 5, 0))}
                                                size={1}
                                        />
                                        <p className="text-dropped">
                                            {message.files.other}</p> <Pie
                                                text={data.basic[15]}
                                                percentage={toPercent(data.basic[6], 3, 0)}
                                                colour={find_color(toPercent(data.basic[6], 3, 0))}
                                                size={1}
                                        />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className="results_tab">
                                    <div>
                                        {print_green(getGreen(data.green, Language), 0, Language)}
                                    </div>
                                    <Card.Header style={{"background-color": "#fff", "border-bottom": "none", "margin-top": "auto"}}>
                                        <ContextAwareToggle eventKey="4"/>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body>
                                            {print_green(getGreen(data.green, Language), 1, Language)}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </section>
                        <Footer/>
                        </>
                    ) } { (data.basic === 'This domain has no stats yet ! Statistics are gathered by users use of the extension and the website !') && (
                    <>
                    <section className="domain-stats">
                        <div className="jobs">
                            <div className="jobs_job">
                                <h2 className="text-secondary">
                                    {error.noData[0]}
                                </h2>
                                <p>
                                {error.noData[1]}
                                </p>
                            </div>
                        </div>
                    </section>
                    </>
                    )}
                    
            </section>
            </>
        </main>
    );
}

export default Stats
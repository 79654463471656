function toPercent(num, min, max) {
    const MIN = Number(min);
    const MAX = Number(max);
    let parsed = parseInt(num);
    if (MIN > MAX) {
        if (num === MAX) {
            return 100;
        }
        if (num >= MIN) {
            return 10;
        } if (num <= max) {
            return 100;
        }
        parsed = MIN - parsed;
        let percent = parsed / min;
        return percent * 100;
    } else if (MIN < MAX) {
        if (num === MIN) {
            return 100;
        }
        if (num <= MIN) {
            return 100;
        } if (num >= MAX) {
            return 10;
        }
        let percent = parsed / max;
        return percent * 100;
    }
}

export default toPercent;
import getMessage from "./Get-message";

function getGreen(data, Language) {
    let green = [];
    console.log(Language)
    if (data.greenweb && data.greenweb.hosted_by !== undefined && data.greenweb.green === true) {
      green[0] = getMessage(Language, 0).green[0] + data.greenweb.hosted_by;
      if (data.greenweb.supporting_documents[0]) {
        green[1] = getMessage(Language, 0).green[1];
        for (let i = 0; ; ++i) {
          if (data.greenweb.supporting_documents[i]) {
            green[i + 2] =
              data.greenweb.supporting_documents[i].title +
              ': \n' +
              data.greenweb.supporting_documents[i].link;
          } else break;
        }
      }
    } else green[0] = getMessage(Language, 0).green[3];
    return green;
}

export default getGreen;
import { useCookies } from 'react-cookie';
import dotenv from 'dotenv';
import { Link } from "react-router-dom";
import '../../css/main.css'
import getMessage from "./Get-message";

dotenv.config();

function Footer() {
    const [cookies] = useCookies(['Language']);

    const message = getMessage(cookies.Language, 0);
    return (
        <div className="footer">
                <div className="summary">
                    <li>
                        <Link to="/">
                            {message.topbar[1]}
                        </Link>
                    </li>
                    <li>
                        <Link to="/Stats">
                            {message.topbar[2]}
                        </Link>
                    </li>
                    <li>
                        <a className="menu-nav_link"
                            href="https://www.les-vikings.fr"
                            target="_blank"
                            rel="noopener noreferrer">
                            {message.topbar[0]}
                        </a>
                    </li>
                </div>
                <div className="copy">
                    <p>v{process.env.REACT_APP_VERSION}  |  &copy; Vikings Technologies 2021</p>
                </div>
            </div>
    )
}

export default Footer;
import getMessage from "./Get-message";

function print_green(greenweb, option, Language) {
    if (greenweb[1]) {
      const elements = [...greenweb];
      elements.splice(1, 1);
      elements.splice(0, 1);
      const items = [];
      for (const [index, value] of elements.entries()) {
        items.push(
          <li key={index}>
            <a
            
            href={value.split('\n')[1]}
            target="_blank"
            rel="noopener noreferrer"
            >
              {value.split(':')[0]}
            </a>
          </li>
        );
      }
      if (option === 0) {
        return (
            <>
              <h2 className="text-tertiary">{greenweb[0]}</h2>
              
            </>
          );
      } else if (option === 1) {
        return (
            <>
                <p className="text-dropped">{getMessage(Language, 0).green[2]}</p>
                <p className="text-tertiary">
                    {greenweb[1]} {items}
                </p>
            </>
        )
      }
    } else if (greenweb[0]) {
        if (option === 0) {
            return (
                <>
                  <h2 className="text-secondary" style={{"margin-top": "8rem"}}>{greenweb[0]}</h2>
                  
                </>
            );
        } else if (option === 1) {
            return (
                <>
                    <p>{getMessage(Language, 0).green[2]}</p>
                </>
            )
        }
    }
}

export default print_green;
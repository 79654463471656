import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


const Loading = ({ Type }) => {
    return (
        <Loader
          type={Type}
          color="#6ac06c"
          height={150}
          width={200}
          timeout={0}
        />
      );
};

export default Loading;
import dotenv from 'dotenv';
dotenv.config();

function logger(log) {
    if (process.env.REACT_APP_ENV === "production") {
        return;
    } else {
        console.log(log);
    }
}

export default logger;
import React, { useEffect, useState } from "react";

const Circle1 = ({ colour, pct}) => {
  const r = 20;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={170}
      cy={29}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={pct ? "0.3rem" : "0.1rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="butt"
    ></circle>
  );
};

const Text1 = ({ percentage }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={"1rem"}
      fontWeight={"900"}
    >
        {percentage.toFixed(0)}
    </text>
  );
};

const Circle2 = ({ colour, pct}) => {
  const r = 50;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={125}
      cy={75}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={pct ? "0.5rem" : "0.2rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="butt"
    ></circle>
  );
};

const Text2 = ({ percentage }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={"2.5rem"}
    >
        {percentage.toFixed(0)}
    </text>
  );
};

const Circle3 = ({ colour, pct}) => {
  const r = 70;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={pct ? "0.7rem" : "0.3rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="butt"
    ></circle>
  );
};

const Text3 = ({ percentage }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={"3rem"}
    >
        {percentage.toFixed(0)}
    </text>
  );
};

const Pie = ({ text, percentage, colour, size }) => {

    const [pct, setPct] = useState(1);

    useEffect(() => {
        setPct(percentage);
    }, []);

    if (!size || size === 3) {
      return (
        <svg width={200} height={200}>
        <g transform={`rotate(-90 ${"100 100"})`}>
            <Circle3 colour="lightgrey"/>
            <Circle3 colour={colour} pct={pct}/>
        </g>
        <Text3 percentage={text !== undefined ? text : pct} />
        </svg>
      );
    } if (size === 2) {
      return (
        <svg width={150} height={150}>
        <g transform={`rotate(-90 ${"100 100"})`}>
            <Circle2 colour="lightgrey"/>
            <Circle2 colour={colour} pct={pct}/>
        </g>
        <Text2 percentage={text !== undefined ? text : pct} />
        </svg>
      );
    } if (size === 1) {
      return (
        <svg width={60} height={60}>
        <g transform={`rotate(-90 ${"100 100"})`}>
            <Circle1 colour="lightgrey"/>
            <Circle1 colour={colour} pct={pct}/>
        </g>
        <Text1 percentage={text !== undefined ? text : pct} />
        </svg>
      );
    }
    
};

export default Pie;
